*::before,
::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 14px;
    font-family: "Inter", sans-serif;
}
.font{
    font-family: "Inter", sans-serif !important;
} 

/* Non-navbar specific stuff moved to bottom of file */
.nav {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    /* border-bottom: 1px solid #E2E2E2; */
}

.nav-left-sec{
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.nav__collapser {
    font-size: 23px;
    font-weight: bolder;
}

/* .nav__brand {
    width: 30px !important;
} */
.nav__brand {
    font-family: 'Fredoka', sans-serif ;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 31px;
}
.logo-img{
    width: 100%;
}

.nav__collapsable {
    font-weight: 500;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    /* This is what hides the content */
    overflow: hidden;
    max-height: 0;
    transition: padding 500ms ease, max-height 250ms ease;
}


.nav--expanded .nav__collapsable {
    max-height: 500px;
    /* This is what shows the content */
    /* margin-top: 1rem; */
    padding: 2rem 0;
    z-index: 1;
}


.profile-small{
    display: none;
}

.nav-content-wrapper{
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
}
.nav-content-wrapper *{
    width: fit-content;
}



.nav-left-sec .ant-btn-compact-first-item{
    display: none;
}


.nav-left-sec .ant-btn-compact-last-item{
    border-radius: 6px !important;
}


.link-item{
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
    color: grey;
    transition: .2s all ease-in-out;
}

.link-item:hover{
    color: black;
}



.nav__collapsable .ant-space-compact-block {
    width: fit-content;
}

@media (width < 780px){
    .profile-small{
        display: inline;
    }
    .profile-big{
        display: none;
    }
}

/* Desktop mode styles */
@media (min-width: 780px) {

    .nav-left-sec{
        width: fit-content;

    }
    .nav {
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

    }

    .nav__collapser {
        display: none;
    }

    .nav__collapsable {
        max-height: 500px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .nav__collapsable {
        display: flex;
        gap: 2rem;
    }


}

@media (min-width: 1000px) {
    html {
        font-size: 1.25rem;
    }
}

a {
    text-decoration: none;
    color: #000;
}

