@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


/* font-family: 'Fredoka', sans-serif;
font-family: 'Inter', sans-serif; */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  --primary-color: #18181A;
  --secondary-color: #242527;
  --pri-bg-color: #FBFBFB;
  --sec-bg-color: #ffffff;
}

body {
  background-color: var(--pri-bg-color);
  font-family: 'Inter', sans-serif !important;
}


/* COMMON */
.section-head {
  font-family: 'Fredoka', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  gap: 20px;
}

.section-icon {
  font-size: 20px;
  font-weight: bold;
}


/* HOME */

.Home-section {
  min-height: 650px;
  padding: 20px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  position: relative;
  overflow:hidden;
  margin-top: -90px;
}
.home-left{
  width: 100%;
  margin-top: -80px;
}

.tutorial-link{
  margin-top: 10px;
}
.home-right{
  width: 100%;

}
.gome-img-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-img{
  width: 400px;
  object-fit: cover;

}
.home-buttons{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Home-head {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}

.Home-head-title {
  font-family: 'Fredoka', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 116.5%;
}

.Home-head-para {
  font-style: normal;
  font-weight: 275;
  font-size: 14px;
  line-height: 21px;
}

/* 

.Home-Card {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  background-color: var(--sec-bg-color);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  font-weight: 500;
}

.Home-card-head {
  display: flex;
  gap: 10px;
  align-items: center;
}

.btn-link-home {
  width: fit-content;
}

.Home-card-head-icon {
  font-size: 30px;
  font-weight: bold;
}

.Home-card-button {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 10px;
}
 */



/* ADD QUSTION */

.Add-question-section {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-section-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;

}

.input-row-col {
  margin-top: 20px;
}

/* QUSTIONS */
.Questions-section {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  margin: auto;
}

.Questions-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;

}

.collapse-tag {
  margin-top: 10px;
}

/* .question-update-btn {
  margin-top: 10px;
} */

/* GENERATE PAPER PAGE */
.Generate-paper-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  width: 100%;
  margin: auto;
}

.Generate-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.question-criteria-wrapper {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5px;
  border-radius: 6px;
  box-shadow: 0px 3px 25px 3px rgba(0, 0, 0, 0.1);
  gap: 5px;

}


.spec-item {
  width: 100%;
  margin: 0;
}


.add-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 10px;
}

.Generate-btn {
  margin-top: 20px;
}


/* RANDOM GENERATE */

.diffulty-ratio-wrapper {}

/* QUESTION PAPER */

.Question-paper-section {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 20px;
  width: 100%;
  /* aspect-ratio: 1/1.4 ; */
  margin: auto;
  justify-content: center;

}


/* FOOTER */
.Footer {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  font-weight: 400;
  font-size: 14px;
  color: rgb(184, 184, 184);
  text-align: center;

  position: fixed;
  bottom: 0;
  background-color: #FBFBFB;


}






/* LOGIN PAGE */
.Login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.login-form {
  max-width: 400px;
  width: 100%;
}

.login-head {
  font-family: 'Fredoka', sans-serif ;
  font-weight: 600;
  font-size: 30px;
  color: #121212;
  text-align: left;
  max-width: 400px;
  width: 100%;
}

.login-para {
  max-width: 400px;
  width: 100%;
  font-family: 'Poppins';
  font-weight: 400;
  color: rgb(41, 41, 41);
  margin-bottom: 20px;
  margin-top: 5px;
}

#login-line {
  color: #dedede !important;
  border-color: #dedede !important;
}





/* SETTINGS */
.settings {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  margin: auto;
}

.settings-conent-wrapper {
  padding: 30px 20px;
}

.sub-inputs {
  display: flex;
  gap: 10px;
}

.samll-label {
  display: none;
}





/* CREATE */
.generate-opt-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  padding: 100px 30px;
  padding-top: 50px;
}

.custom-paper-card,
.random-paper-card {
  max-width: 100%;
  width: 300px;
  /* Set a fixed width for both cards */
  height: 362px;
  /* Set a fixed height for both cards */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}






/* TUTORIAL SECTION */


.tutorial-section {
  padding: 20px;
}

.tutorial-page {
  border-radius: 6px;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Header styles */
.tutorial-section header {
  text-align: center;
  margin-bottom: 20px;
}

.tutorial-section h1 {
  font-size: 28px;
  color: #1890ff;
}

/* Section styles */
.tutorial-section section {
  margin-bottom: 20px;
}

.tutorial-section h2 {
  font-size: 24px;
  color: #1890ff;
  margin-bottom: 10px;
}

.tutorial-section h3 {
  font-size: 20px;
  color: #1890ff;
  margin-top: 20px;
}

.tutorial-section ol {
  list-style-type: decimal;
  margin-left: 20px;
}

.tutorial-section li {
  margin-bottom: 8px;
  font-size: 14px;
}

/* Button styles */
.tutorial-section .ant-btn {
  margin-top: 8px;
  margin-right: 8px;
}

/* Text styles */
.tutorial-section .ant-typography {
  font-size: 16px;
  color: #333;
}

/* Note styles */
.tutorial-section .note {
  font-style: italic;
  color: #999;
}


.main{
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.question-info-tags{
  display: flex;
  align-items: center;
  justify-content: space-between;
}


@media(width < 680px) {
  .generate-opt-section {
    flex-direction: column;
    gap: 20px;
    padding: 50px 10px;
  }
}



@media(max-width:576px) {
  .samll-label {
    display: inline;
  }

  .tutorial-page {
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
  }
  .question-info-tags{
    flex-direction: column;
    gap: 10px;
    /* align-items: flex-start; */
    justify-content: center;
  }
  .left-align{
    align-self: flex-start;
  }
  .right-align{
    align-self: flex-end;
  }

}


@media (min-width: 711px) {

  /* HOME */

  .home-buttons{
    flex-direction: row;
  }

  .Home-section {
    flex-direction: row;
    overflow: hidden;
    margin: 0;
  }
  .Home-head {
    margin: 0;
  }

  .gome-img-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .home-left{
    width: 50%;
  }
  .home-right{
    width: 50%;
    /* position: relative; */
  }
  .home-img{
    z-index: -1;
    position: absolute;
    left: 10;
    width: 1050px;
    object-fit: cover;
  
  }

  .Home-head-title {
    max-width: 600px;
    width: 100%;
    font-weight: 500;
    font-size: 40px;
  }

  .Home-cards-wrapper {
    flex-direction: row;
    gap: 10px;
  }

}


@media (min-width: 950px) {

  /* HOME */

  .Home-section {
    padding-left: 100px;
    padding-right: 100px;
  }

  .Home-head-title {
    font-size: 50px;
  }

  .Home-Card {
    width: 365px;
  }







}

@media (min-width: 500px) {

  /* GENERATE */
  .question-criteria-wrapper {
    flex-direction: row;
  }

  .spec-item {
    width: 20%;
    margin: 0;
  }

  .spec-item:nth-child(4) {
    flex: 1;
  }


}